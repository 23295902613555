import styles from "./ProductList.module.css"
import {Link} from "react-router-dom"
import {useState, useEffect} from "react"
import Title from "./Title"
import QuantityBtn from "./QuantityBtn"

export default function ProductList() {

    let [productList, setProductList] = useState([])

    useEffect(()=>{
        // 1 : 沒有第二個參數時COMPONWNT每次RENDER都會觸發
        // 2 : DEPENDENCY ARRAY 是空ARRAY時只會在第一次網頁RENDER時會觸發
        // 3 : DEPENDENCY ARRAY 是有變數時 第一次網頁RENDER時+指定變數改變會觸發 

        fetch('https://hoyinleung.github.io/demoapi/react-basic-product.json')
            .then(Response => Response.json())
            .then(data => setProductList(data))
        
        console.log(productList)
    },[])  // <== DEPENDENCY ARRAY

    return (
        // React Fragment
        <> 
            <Title mainTitle="React入門水果店" />
            <div>
                {                    
                    productList.map(product=>(
                        <div className={styles.productBorder} key={product.id}>
                            {product.name} <br/>
                            {product.price} <br/>
                            <Link to={'/product/'+product.id}>
                                <img src={process.env.PUBLIC_URL+'/img/'+product.image} alt="Apple"/><br/>
                            </Link>
                            
                            {product.description} <br/>
                            <QuantityBtn productInfo={product} />
                        </div>
                    ))                    
                }      
            </div>        
        </>
    )
}
