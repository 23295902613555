
export default function Title(props) {
  return (
    <div>            
        <h1 style={{borderBottom:'5px solid red', textAlign:'center'}}>
            {props.mainTitle}
        </h1>
    </div>
  )
}
